import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { Email, User } from '../../../../pages/auth/models';
import { AuthService, EmailService } from '../../../../pages/auth/services';
import { routes } from '../../../../consts';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { AssetsService } from 'src/app/pages/assetlink/services/assets.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() isMenuOpened: boolean;
  @Output() isShowSidebar = new EventEmitter<boolean>();
  public user$: Observable<User>
  public emails$: Observable<Email[]>
  public routers: typeof routes = routes;

  public search :[] ;
  public searchlen:number;
  public customer_id;
  public user_type:any;
  keyword;
  searchKeyword;

  constructor(
    private userService: AuthService,
    private emailService: EmailService,
    private router: Router,
    private service:AssetsService,
    private _snack$: SnackbarService
  ) {
    this.user$ = this.userService.getUser();
    this.emails$ = this.emailService.loadEmails();
    this.customer_id=localStorage.getItem('customer_id');
    this.user_type =  localStorage.getItem('role');
    this.searchlen=0;
    this.search=[];
  }

  ngOnInit(){
   
    this.searchKeyword = localStorage.getItem('keyword')?localStorage.getItem('keyword'):'';
    //alert(this.searchKeyword);
  }

  public openMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;

    this.isShowSidebar.emit(this.isMenuOpened);
  }
  /**
   * Signs out an user
   */
  public signOut(): void  {
    localStorage.clear(); 
    this.userService.signOut();
    this.router.navigate([this.routers.LOGIN]);
  }

  /**
   * returns the global search 
   */

  public search_result(key):void{

    let params={
      "customer_id": this.customer_id,
      "keyword": key.form.controls.keyword.value
    };

    //alert(key.form.controls.keyword.value);
    //localStorage.setItem("keyword",key.form.controls.keyword.value);

    this.search=[];
    this.searchlen=0;

    if(params.keyword.length ==0){
      return ;
    }
    this.service.search(params).subscribe({
      next:(response :any)=>{
        this.search=response.result.atm_list;
      },
       error: (err: Error) => {
         console.log(err)
       },
       complete: () => {
         this.searchlen=this.search.length;
       },

    });

    
  }

  /**
   * 
   * returns instutiion assigned id of an atm shown in search
   */

  
  public displayFn(item){

    return item ? item.InstitutionAssignedID : "";
    

  }

  /**
   * redirect a selected item to asset list
   */

  public navToProd(obj){
   // console.log(obj.option.value.InstitutionAssignedID);
    localStorage.setItem('keyword',obj.option.value.InstitutionAssignedID);
    let item =obj.option.value;
    let redirectTo = item.redirect_type;
    this.router.navigate([redirectTo], {queryParams : { atm_id :item.atmid }}).then(
      ()=>{
        window.location.reload();
      }

    );
  }

  public clearSearch():void{
    localStorage.removeItem('keyword');
    this.searchKeyword='';
    this.router.navigate(["/assets"]).then(
      ()=>{
        window.location.reload();
      }

    );
  }
  
}
