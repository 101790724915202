import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http'; 

import { User } from '../models';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  constructor(private http:HttpClient){
  }
  // public login(): void {
  //   localStorage.setItem('token', 'token');
  // }

  public sign(): void {
    localStorage.setItem('token', 'token');
  }

  public signOut(): Observable<any> {
    let params={
      "username": localStorage.getItem('username')
    };
    return this.http.post(environment.baseURL + "/user-signout"+ environment.api_postfix,params,{});
    
  }

  public getUser(): Observable<User> {
    return of({
      name: localStorage.getItem('username'),
      lastName: ''
    });
  }

  public login(params):Observable<any>{
    return this.http.post(environment.baseURL + "/user-signin"+ environment.api_postfix,params,{});

  }

  public verifyOtp(params):Observable<any>{
    return this.http.post(environment.baseURL + "/confirm-user-signup" + environment.api_postfix,params,{});
  }

  public resendVerificationCode(params):Observable<any>{
    return this.http.post(environment.baseURL + "/resend-verification-code" + environment.api_postfix,params,{});
  }

  public resetPassword(params):Observable<any>{
    return this.http.post(environment.baseURL + "/reset-password" + environment.api_postfix,params,{});
  }
}
