import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { JobService } from '../job/services/job.service';
import { BsDatepickerDirective, BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-service-ticket-modal',
  templateUrl: './service-ticket-modal.component.html',
  styleUrls: ['./service-ticket-modal.component.css']
})
export class ServiceTicketModalComponent implements OnInit {
  //@ViewChild(BsDaterangepickerDirective, { static: false }) datepicker?: BsDaterangepickerDirective;
  @ViewChild(BsDatepickerDirective, { static: false }) datepicker?: BsDatepickerDirective;
  @HostListener('window:scroll')
  onScrollEvent() {
    this.datepicker?.hide();
  }

  public title: string;
  public customer_id: string = '';
  public user_type: string;
  public user_id: string;
  public data;
  public customer_list: object = [];
  public customerId: string = '';
  public selectedJobID: string = '';
  public isLoading = false;
  public isCreateJob: boolean = false;
  
  public date:any;
  bsRangeValue: Date[];
  public today = new Date();

  public selectJobForm: FormGroup;
  public createJobForm: FormGroup;

  public dropdownListData;
  
  public getJobIdsParams = {
    "company_id": '',
    "job_status_id": '',
    "year" : '',
    'month' : '',
    'job_id' : '',
    'customerid' : '',
    'job_number' : ''
  };

  constructor(private modalRef: BsModalRef,   
              private jobService: JobService,
              private _formBuilder: FormBuilder,
              private _snack$: SnackbarService ) { }

  ngOnInit(): void {
    this.customer_id =  localStorage.getItem('customer_id');
    this.user_type =  localStorage.getItem('role');
    this.user_id =  localStorage.getItem('user_id');
    this.selectedJobID = this.data.selectedID;
    this.generateCreateJobForm();
    this.generateSelectJobForm();
    //this.generateSelectJobForm();
    //this.getDropdownListData();
    this.fetchCustomerList();
  }

  public generateSelectJobForm = (): void => {
    this.selectJobForm = this._formBuilder.group({
      JobId: ['',  Validators.required],
      ServiceId: [''],
      TechnicianId: [''],
      emailContent: ['']
    });
  };

  public generateCreateJobForm = (): void => {
    this.createJobForm = this._formBuilder.group({
      jobName: ['',  Validators.required],
      jobNumber: ['', Validators.required],
      bsRangeValue: ['', Validators.required]
    });
  };

  /* filterByDataCollect(dataValue:any, dataType:any, event : Event)
  {
    let selectElementText = event.target['options']
      [event.target['options'].selectedIndex].text;
    if(dataType == 'job_customer')
    {      
      this.customerId = dataValue;
      if(selectElementText == 'Customer')
      {
        selectElementText = 'All';
      }

    }
    
  } */

  onCustomerSelect(id) {
    console.log('id', id);
    this.getDropdownListData(id);
  }

  private fetchCustomerList() {
    let customerListRequestBody = {
      "Fetch": "CUSTOMER_LIST",
      "CustomerId": this.customer_id ? this.customer_id : '',
      "UserId": this.user_id,
      "Type": this.user_type
    };
    this.isLoading = true;
    this.jobService.get_dropdown_list(customerListRequestBody).subscribe({      
      next: (response: any) => {        
      console.log('response', response);
      this.customer_list = response.data.customerNames;
      this.isLoading = false;
    },
    error: (err: Error) => {
      console.error(err['error'].message);
    },
    complete: () => {
      //this.isLoading = false;
    },
    });
  }

  private getDropdownListData(customerId) {
    this.customerId = customerId;
    let dropDownRequestBody = {
      "Fetch": "DROPDOWN_LIST",
	    "CustomerId": this.customerId
    };
    console.log('dropDownRequestBody', dropDownRequestBody);
    this.isLoading = true;
    this.jobService.get_dropdown_list(dropDownRequestBody).subscribe({      
      next: (response: any) => {        
      console.log('response', response);
      this.dropdownListData = response.data;
      this.isLoading = false;
    },
    error: (err: Error) => {
      console.error(err['error'].message);
    },
    complete: () => {
      //this.isLoading = false;
    },
    });
  }

  /**
   * on cancel just removes the popup
   */

  onCancel(){
    this.modalRef.hide();
  }

  submit() {
    this.isLoading = true;
    const start_date: Date = this.createJobForm.value.bsRangeValue
      ? this.createJobForm.value.bsRangeValue[0]
      : '';
    const due_date: Date = this.createJobForm.value.bsRangeValue
      ? this.createJobForm.value.bsRangeValue[1]
      : '';
    let requestBody = {};
    requestBody = JSON.parse(JSON.stringify(this.selectJobForm.value));
    if(this.isCreateJob) {
      let createObj = {
        createJob: {
          CustomerId: this.customerId,
          StartDate: start_date ? start_date.toISOString().split('T')[0] : '',
          DueDate: due_date ? due_date.toISOString().split('T')[0] : '' ,
          JobNumber: (this.createJobForm.value.jobNumber).toString(),
          JobName: this.createJobForm.value.jobName
        }
      }
      delete requestBody['JobId'];
      Object.assign(requestBody, createObj);
    }

    Object.assign(requestBody, { ATMID: this.selectedJobID });
    this.jobService.create_service_ticket(requestBody).subscribe({      
      next: (response: any) => {
        if(response && response.code === 200) {
          this.onCancel();
          this._snack$.notifySuccess(response.msg, 'Success');
        }        
    },
    error: (err: Error) => {
      console.error(err['error'].message);
      this._snack$.notifyError(err['error'].message, 'OK');
    },
    complete: () => {
      this.isLoading = false;
    },
    });
  }

  toggleJobType() {
    this.isCreateJob = !this.isCreateJob;
    this.resetForms();
  }

  resetForms() {
    if(this.isCreateJob) {
      this.selectJobForm.patchValue({JobId: ''});
    }
    else {
      this.generateCreateJobForm();
    }
  }

}
