import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { request } from 'http';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { JobService } from '../job/services/job.service';

@Component({
  selector: 'app-create-job',
  templateUrl: './create-job.component.html',
  styleUrls: ['./create-job.component.css']
})
export class CreateJobComponent implements OnInit {

  public title: string;
  public headerTitle: string;
  public buttonTitle: string;
  public createJobForm: FormGroup;
  public customer_id: string = '';
  public date:any;
  bsRangeValue: Date[];
  public today = new Date();
  public data;
  public customer_list = [];
  public user_type: string;
  public user_id: string;
  public customerId: string = '';
  public isDateValid = true;
  
  public isLoading = false;
  

  
  constructor(private _formBuilder: FormBuilder,
              private modalRef: BsModalRef,
              private jobService: JobService,
              private _snack$: SnackbarService) { }

  ngOnInit(): void {
    this.customer_id =  localStorage.getItem('customer_id');
    this.user_type =  localStorage.getItem('role');
    this.user_id =  localStorage.getItem('user_id');
    this.headerTitle = this.title === 'create' ? 'Create Job' : 'Edit Job';
    this.buttonTitle = this.title === 'create' ? 'CREATE' : 'EDIT';

    this.generateCreateJobForm();
    this.fetchCustomerList();

    if(this.title === 'edit') {
      this.fetchJobInfo();
    }

    this.createJobForm.get("bsRangeValue").valueChanges.subscribe(selectedValue => {
      let startDate = selectedValue[0];
      if((startDate).setHours(0,0,0,0) < (this.today).setHours(0,0,0,0)) {
        this.isDateValid = false;
      } else {
        this.isDateValid = true;
      }
    })

  }
CustomerId
  public generateCreateJobForm = (): void => {
    this.createJobForm = this._formBuilder.group({
      CustomerId: ['', Validators.required],
      JobName: ['',  Validators.required],
      JobNumber: ['', Validators.required],
      bsRangeValue: ['', Validators.required],
      JobDescription: ['', Validators.required]
    });
  };

  private fetchCustomerList() {
    let customerListRequestBody = {
      "Fetch": "CUSTOMER_LIST",
      "CustomerId": this.customer_id ? this.customer_id : '',
      "UserId": this.user_id,
      "Type": this.user_type
    };
    this.isLoading = true;
    this.jobService.get_dropdown_list(customerListRequestBody).subscribe({      
      next: (response: any) => {        
      this.customer_list = response.data.customerNames;
      this.isLoading = false;
    },
    error: (err: Error) => {
      console.error(err['error'].message);
    },
    complete: () => {
      //this.isLoading = false;
    },
    });
  }

  /**
   * on cancel just removes the popup
   */

   onCancel(){
    this.modalRef.hide();
  }

  // to pre populate edit job fields
  fetchJobInfo() {
    this.jobService.view_job(this.data).subscribe({      
      next: (response: any) => {        
      const editJobData = response.data;
      let dateRange =[];
      dateRange[0] = new Date(editJobData.StartDate);
      dateRange[1] = new Date(editJobData.DueDate);
      this.createJobForm.patchValue(
        {
          CustomerId: this.customer_list.find(customer => customer.CustomerId === editJobData.CustomerId) ? editJobData.CustomerId : '' , 
          JobName: editJobData.JobName, 
          JobNumber: editJobData.JobNumber, 
          JobDescription: editJobData.JobDescription,
          bsRangeValue: dateRange
        });
    },
    error: (err: Error) => {
      console.error(err['error'].message);
    },
    complete: () => {
      //this.isLoading = false;
    },
    });
  }

  submit() {
    this.isLoading = true;
    const start_date: Date = this.createJobForm.value.bsRangeValue
      ? this.createJobForm.value.bsRangeValue[0]
      : '';
    const due_date: Date = this.createJobForm.value.bsRangeValue
      ? this.createJobForm.value.bsRangeValue[1]
      : '';
    delete this.createJobForm.value.bsRangeValue;
    let requestBody = {};
    requestBody = JSON.parse(JSON.stringify(this.createJobForm.value));
    Object.assign(requestBody, { StartDate : start_date ? start_date.toISOString().split('T')[0] : '', 
                                DueDate: due_date ? due_date.toISOString().split('T')[0] : '' , 
                                JobNumber: (this.createJobForm.value.JobNumber).toString(),});
    if(this.title === 'create') {
      this.jobService.create_job(requestBody).subscribe({      
        next: (response: any) => {
          if(response && response.code === 200) {
            this.onCancel();
            this._snack$.notifySuccess(response.msg, 'Success');
          }        
      },
      error: (err: Error) => {
        console.error(err['error'].message);
        this._snack$.notifyError(err['error'].message, 'OK');
      },
      complete: () => {
        this.isLoading = false;
        this.modalRef.hide();
      },
      });
    } else {
      Object.assign(requestBody,this.data);
      this.jobService.edit_job(requestBody).subscribe({      
        next: (response: any) => {
          if(response && response.code === 200) {
            this.onCancel();
            this._snack$.notifySuccess(response.msg, 'Success');
          }        
      },
      error: (err: Error) => {
        console.error(err['error'].message);
        this._snack$.notifyError(err['error'].message, 'OK');
      },
      complete: () => {
        this.isLoading = false;
      },
      });
    }
  }

}
