import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardPageComponent } from './pages/dashboard/containers';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthGuard, CustGuard, AdminCustGuard } from './pages/auth/guards';
import { routes as routesList } from './consts/routes';
import { CustomerComponent } from './pages/customer/components/customer/customer.component';
import { AssetlinkComponent } from './pages/assetlink/components/assetlink/assetlink.component';
import { ApprovalToolComponent } from './pages/approval-tool/approval-tool.component';
import { ApprovalToolAdminComponent } from './pages/approval-tool-admin/approval-tool-admin.component';
import { JobComponent } from './pages/job/job.component';
import { JobAdminComponent } from './pages/job-admin/job-admin.component';
import { FileNotFoundComponent } from './pages/file-not-found/file-not-found.component';
import { SwitchUserComponent } from './pages/switch-user/switch-user.component';

const routes: Routes = [

  {
    path: 'assets',
    pathMatch: 'full',
    canActivate: [AdminCustGuard],
    component: AssetlinkComponent

  },

  {
    path: 'customer',
    pathMatch: 'full',
    canActivate: [CustGuard],
    component: CustomerComponent
  },
  {
    path: 'approval-tool',
    pathMatch: 'full',
    canActivate: [CustGuard],
    component: ApprovalToolComponent
  },
  {
    path: 'approval-tools',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: ApprovalToolAdminComponent
  },
  {
    path: 'job',
    canActivate: [AdminCustGuard],
    loadChildren: () => import('./pages/job-section/job-section.module').then(m => m.JobSectionModule)
  },
  {
    path: 'user',
    canActivate: [AdminCustGuard],
    loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'jobe',
    pathMatch: 'full',
    canActivate: [CustGuard],
    component: JobComponent
  },
  {
    path: 'jobs',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: JobAdminComponent
  },
  {
    path: routesList.DASHBOARD,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: DashboardPageComponent
  },
  {
    path: 'file-not-found',
    pathMatch: 'full',
    component: FileNotFoundComponent
  },
  {
    path: 'tables',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/tables/tables.module').then(m => m.TablesModule)
  },
  {
    path: ' ',
    component: NotFoundComponent
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'switch',
    pathMatch: 'full',
    canActivate: [AdminCustGuard],
    component: SwitchUserComponent
  },
  {
    path: 'customer-mapping',
    canActivate: [AdminCustGuard],
    loadChildren: () => import('./pages/customer-mapping/customer-mapping.module').then(m => m.CustomerMappingModule)
  },
  {
    path: 'vendor-mapping',
    canActivate: [AdminCustGuard],
    loadChildren: () => import('./pages/vendor-mapping/vendor-mapping.module').then(m => m.VendorMappingModule)
  },
  {
    path: 'institution',
    canActivate: [AdminCustGuard],
    loadChildren: () => import('./pages/institution-management/institution-management.module').then(m => m.InstitutionManagementModule)
  },
  {

    path: 'roles',
    canActivate: [AdminCustGuard],
    pathMatch: 'full',
    loadChildren: () => import('./pages/user-role/user-role.module').then(m => m.UserRoleModule)
  },

  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
